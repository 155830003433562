import styled, { createGlobalStyle } from 'styled-components'
import React from 'react';
import { Layout } from './layout';
import type { ReactNode } from 'react'

const GlobalStyle = createGlobalStyle`
  pre {
    background: rgb(240, 240, 240);
    padding: 0.5em;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }

  .hi-4 code > span:nth-last-child(4) {
    display: block;
    background-color: pink;
  }

  .hi-5 code > span:nth-last-child(5) {
    display: block;
    background-color: pink;
  }
`

export function HackTheBox({ children, renderBack = true }: { children: ReactNode, renderBack?: boolean }) {
  return (
    <Layout>
      <GlobalStyle />
      {children}
    </Layout>
  )
}
