import styled, { createGlobalStyle } from 'styled-components'
import React from 'react';
import type { ReactNode } from 'react'
import uwu from '../images/uwu.gif';
import { Global } from './global';

const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    font-family: system-ui,-apple-system,blinkmacsystemfont,"Segoe UI",helvetica,arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1.3;
  }

  * {
    box-sizing: border-box;
  }

  code {
    background: rgb(240, 240, 240);
    padding: 2px;
    word-break: break-all;
  }

  h1, h2, h3, h4, h5 {
    font-family: "San Francisco Mono","Monaco","Consolas","Lucida Console","DejaVu Sans Mono","Bitstream Vera Sans Mono",monospace
  }

  h2 {
    margin-top: 2rem;
  }

  blockquote {
    border-left: 6px solid #ccc;
    margin-left: 0;
    padding: 8px 0 8px 30px;
  }

  img {
    width: 100%;
  }
`

const S = {
  Uwu:styled.img`
    height: 10vh;
    width: auto;
    position: fixed;
    bottom: 12px;
    right: 12px;
    opacity: 0.7;
  `
}

export function Layout({ children, renderBack = true, isHome = false }: { children: ReactNode, renderBack?: boolean, isHome?: boolean }) {
  return (
    <Global isHome={isHome}>
      <GlobalStyle />
      {renderBack && <a
        href="/"
        style={{
          display: 'inline-block',
          marginTop: '1rem'
        }}>&lt; back</a>}
      {children}
      <S.Uwu src={uwu}/>
    </Global>
  )
}
